import { ref } from '#imports';

export const useWaitRequest = () => {
  const requestInProgress = ref(false);

  const waitRequest = (callback) => {
    if (requestInProgress.value) return;

    requestInProgress.value = true;

    callback().finally(() => {
      requestInProgress.value = false;
    });
  };

  return {
    requestInProgress,
    waitRequest,
  };
};
